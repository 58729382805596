import _ from 'lodash';
import moment from 'moment';
import { GET_RATES_DATA, REMOVE_RATES_DATA, GET_CONTRACT_CHART_DATA } from '../actions/rates';

const initialState = {
  ratesDataStatus: '',
  ratesData: {},
  contractChartDataStatus: '',
  contractChartData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RATES_DATA.REQUEST: {
      return {
        ...state,
        ratesDataStatus: 'request',
        ratesData: {},
      };
    }
    case GET_RATES_DATA.SUCCESS: {
      let dates = [];
      let dat = [];
      let datCurrent = {};
      let truckStop = [];
      let truckCurrent = {};
      const { data } = action.payload;
      if (!_.isEmpty(data.dat)) {
        dates = data.dat.dataList.map((d) => moment(d[0].start, 'YYYY-MM-DD').format('MMM'));
        dat = data.dat.dataList.map((d) => d[1]?.perTrip?.rateUsd || null);
        datCurrent = data.dat.current;
      }
      if (!_.isEmpty(data.truckStop.dataList)) {
        truckStop = data.truckStop.dataList.map((d) => d[1].flatRate || null);
        truckStop.unshift(null);
        dat.push(null);
        truckCurrent = data.truckStop.current;
      }
      if (_.isEmpty(data.dat) && !_.isEmpty(data.truckStop)) {
        dates = data.truckStop.dataList.map((d) => moment(d[0].start, 'YYYY-MM-DD').format('MMM'));
      } else {
        const nextMonth = moment(_.last(data.dat.dataList)[0].start, 'YYYY-MM-DD').add(1, 'month').format('MMM');
        dates = [...dates, nextMonth];
      }

      return {
        ...state,
        ratesDataStatus: 'ok',
        ratesData: {
          dates, dat, truckStop, datCurrent, truckCurrent,
        },
      };
    }
    case GET_RATES_DATA.FAIL: {
      return {
        ...state,
        ratesDataStatus: 'request',
      };
    }
    case REMOVE_RATES_DATA: {
      return {
        ...state,
        ratesData: {},
        contractChartData: {},
      };
    }
    case GET_CONTRACT_CHART_DATA.REQUEST: {
      return {
        ...state,
        contractChartData: {},
        contractChartDataStatus: 'request',
      };
    }
    case GET_CONTRACT_CHART_DATA.SUCCESS: {
      const { data } = action.payload;
      let dat = [];
      let datCurrent = {};
      if (!_.isEmpty(data.dat)) {
        dat = data.dat.dataList.map((d) => d[1]?.perTrip?.rateUsd || null);
        datCurrent = data.dat.current;
      }
      return {
        ...state,
        contractChartData: { dat, datCurrent },
        contractChartDataStatus: 'ok',
      };
    }
    case GET_CONTRACT_CHART_DATA.FAIL: {
      return {
        ...state,
        contractChartDataStatus: 'request',
      };
    }
    default: {
      return state;
    }
  }
}
