import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_CARRIERS_ALL_STATUSES_LIST = define('GET_CARRIERS_ALL_STATUSES_LIST');

export function getCarriersListAllStatusesRequest(s) {
  return GET_CARRIERS_ALL_STATUSES_LIST.request(() => Api.getCarriersAllStatuses(s)).takeLatest();
}

export const GET_CARRIERS_LIST_BY_STATUS = define('GET_CARRIERS_LIST_BY_STATUS');

export function getCarriersListByStatusRequest(status, page, s, filter) {
  return GET_CARRIERS_LIST_BY_STATUS.request(() => Api.getCarriersByStatus(status, page, s, filter)).takeLatest();
}

export const CREATE_CARRIER = define('CREATE_CARRIER');

export function createCarrierRequest(data) {
  return CREATE_CARRIER.request(() => Api.createCarrier(data));
}

export const GET_CARRIER_BY_ID = define('GET_CARRIER_BY_ID');

export function getCarrierByIdRequest(id) {
  return GET_CARRIER_BY_ID.request(() => Api.getCarrierById(id));
}

export const CHECK_IF_DRIVER_EXISTS = define('CHECK_IF_DRIVER_EXISTS');

export function checkIfDriverExistsRequest(phone) {
  return CHECK_IF_DRIVER_EXISTS.request(() => Api.checkIfDriverExists(phone));
}

export const UPDATE_DRIVERS = define('UPDATE_DRIVERS');

export function updateDriversRequest(data) {
  return UPDATE_DRIVERS.request(() => Api.updateDrivers(data));
}

export const CANCEL_DRIVER = define('CANCEL_DRIVER');

export function cancelDriverRequest(id) {
  return CANCEL_DRIVER.request(() => Api.cancelDriver(id));
}

export const CHANGE_CARRIER = define('CHANGE_CARRIER');

export function changeCarrierRequest(data) {
  return CHANGE_CARRIER.request(() => Api.changeCarrier(data));
}

export const SET_DRIVER_DATA = define('SET_DRIVER_DATA');

export function setDriverData(data) {
  return {
    type: SET_DRIVER_DATA,
    payload: { data },
  };
}

export const CHANGE_DRIVER_DATA = define('CHANGE_DRIVER_DATA');

export function changeDriverData(key, val, i) {
  return {
    type: CHANGE_DRIVER_DATA,
    payload: { key, val, i },
  };
}
