import { GET_API_KEY } from '../actions/apiKey';

const initialState = {
  apiKey: {},
  apiKeyStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_API_KEY.REQUEST: {
      return {
        ...state,
        apiKeyStatus: 'request',
      };
    }
    case GET_API_KEY.SUCCESS: {
      return {
        ...state,
        apiKeyStatus: 'ok',
        apiKey: action.payload.data.keys,
      };
    }
    case GET_API_KEY.FAIL: {
      return {
        ...state,
        apiKeyStatus: 'fail',
        apiKey: {},
      };
    }

    default: {
      return state;
    }
  }
}
