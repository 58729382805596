import {GET_DISCOUNTS, GET_DISCOUNTS_STATE, GET_STATES} from '../actions/discounts';

const initialState = {
  discounts: [],
  discountsStatus: '',
  totalPages: 0,
  stateDiscounts: [],
  stateDiscountsStatus: '',
  stateTotalPages: 0,
  globalDiscount: 0,
  getStatesStatus: '',
  statesUS: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DISCOUNTS.REQUEST: {
      return {
        ...state,
        discountsStatus: 'request',
      };
    }
    case GET_DISCOUNTS.SUCCESS: {

      return {
        ...state,
        discountsStatus: 'ok',
        discounts: action.payload.data.discounts,
        totalPages: action.payload.data.totalPages,
        globalDiscount: action.payload.data.globalDiscount,
      };
    }
    case GET_DISCOUNTS.FAIL: {
      return {
        ...state,
        discountsStatus: 'fail',
        discounts: [],
        totalPages: 0,
        globalDiscount: 0,
      };
    }
    case GET_DISCOUNTS_STATE.REQUEST: {
      return {
        ...state,
        stateDiscountsStatus: 'request',
      };
    }
    case GET_DISCOUNTS_STATE.SUCCESS: {
      return {
        ...state,
        stateDiscountsStatus: 'ok',
        stateDiscounts: action.payload.data.stateDiscounts,
        stateTotalPages: action.payload.data.totalPages,
        globalDiscount: action.payload.data.globalDiscount,
      };
    }
    case GET_DISCOUNTS_STATE.FAIL: {
      return {
        ...state,
        stateDiscountsStatus: 'fail',
        stateDiscounts: [],
        stateTotalPages: 0,
        globalDiscount: 0,
      };
    }
    case GET_STATES.REQUEST: {
      return {
        ...state,
        getStatesStatus: 'request',
      };
    }
    case GET_STATES.SUCCESS: {
      return {
        ...state,
        getStatesStatus: 'ok',
        statesUS: action.payload.data.states,
      };
    }
    case GET_STATES.FAIL: {
      return {
        ...state,
        getStatesStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
