import Api from '../../Api';
import { define } from '../../helpers/redux-request';

export const GET_RATES_DATA = define('GET_RATES_DATA');

export function getRatesDataRequest(data) {
  return GET_RATES_DATA.request(() => Api.getRatesData(data)).takeLatest();
}

export const GET_CONTRACT_CHART_DATA = define('GET_CONTRACT_CHART_DATA');

export function getContractChartDataRequest(data) {
  return GET_CONTRACT_CHART_DATA.request(() => Api.getContractChartData(data)).takeLatest();
}

export const REMOVE_RATES_DATA = 'REMOVE_RATES_DATA';

export function removeRatesDataRequest() {
  return {
    type: REMOVE_RATES_DATA,
    payload: {},
  };
}

export const SEND_QUOTES = define('SEND_QUOTES');

export function sendQuotesRequest(email, url) {
  return SEND_QUOTES.request(() => Api.sendQuotes(email, url));
}
