import { GET_SUBSCRIPTION_PLANS, GET_CURRENT_SUBSCRIPTION_PLAN, REMOVE_SUBSCRIPTION_DATA } from '../actions/subscription';

const initialState = {
  subscriptionPlans: [],
  subscriptionPlansStatus: '',
  currentPlan: {},
  currentPlanStatus: '',
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS.REQUEST: {
      return {
        ...state,
        subscriptionPlansStatus: 'request',
      };
    }
    case GET_SUBSCRIPTION_PLANS.SUCCESS: {
      return {
        ...state,
        subscriptionPlans: action.payload.data.invoices,
        subscriptionPlansStatus: 'ok',
      };
    }
    case GET_SUBSCRIPTION_PLANS.FAIL: {
      return {
        ...state,
        subscriptionPlansStatus: 'fail',
        subscriptionPlans: [],

      };
    }
    case GET_CURRENT_SUBSCRIPTION_PLAN.REQUEST: {
      return {
        ...state,
        currentPlanStatus: 'request',
      };
    }
    case GET_CURRENT_SUBSCRIPTION_PLAN.SUCCESS: {
      return {
        ...state,
        currentPlan: action.payload.data,
        currentPlanStatus: 'ok',
      };
    }
    case GET_CURRENT_SUBSCRIPTION_PLAN.FAIL: {
      return {
        ...state,
        currentPlanStatus: 'fail',
        currentPlan: {},

      };
    }
    case REMOVE_SUBSCRIPTION_DATA: {
      return {
        ...state,
        currentPlan: {},
        subscriptionPlans: [],
      };
    }
    default: {
      return state;
    }
  }
}
