import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_PLAID_LINK_TOKEN = define('GET_PLAID_LINK_TOKEN');

export function getPlaidLinkTokenRequest() {
  return GET_PLAID_LINK_TOKEN.request(() => Api.getPlaidLinkToken());
}

export const EXCHANGE_PLAID_TOKEN = define('EXCHANGE_PLAID_TOKEN');

export function exchangePlaidTokenRequest(data) {
  return EXCHANGE_PLAID_TOKEN.request(() => Api.exchangePlaidToken(data));
}

export const CHECK_PLAID_ACCOUNT = define('CHECK_PLAID_ACCOUNT');

export function checkPlaidAccountRequest(userId) {
  return CHECK_PLAID_ACCOUNT.request(() => Api.checkPlaidAccount(userId));
}

export const CHECK_USER_PLAID_ACCOUNT = define('CHECK_USER_PLAID_ACCOUNT');

export function checkUserPlaidAccountRequest(userId) {
  return CHECK_USER_PLAID_ACCOUNT.request(() => Api.checkPlaidAccount(userId));
}

export const CHARGE_FROM_SHIPPER = define('CHARGE_FROM_SHIPPER');

export function chargeFromShipperRequest(data) {
  return CHARGE_FROM_SHIPPER.request(() => Api.chargeFromShipper(data));
}

export const PAY_TO_DRIVER = define('PAY_TO_DRIVER');

export function payToDriverRequest(shipmentId, accountId, userId, status, updatedFileds) {
  return PAY_TO_DRIVER.request(() => Api.payToDriver(shipmentId, accountId, userId, status, updatedFileds));
}

export const BANK_ACCOUNT_INFO = define('BANK_ACCOUNT_INFO');

export function getAccountInfo() {
  return BANK_ACCOUNT_INFO.request(() => Api.getBankAccountInfo());
}

export const DELETE_ACCOUNT_INFO = define('DELETE_ACCOUNT_INFO');

export function deleteAccount(id) {
  return DELETE_ACCOUNT_INFO.request(() => Api.deleteBankAccount(id)).takeLatest();
}

export const CHANGE_ACCOUNT_TYPE = define('CHANGE_ACCOUNT_TYPE');

export function changeAccountType(id, status) {
  return CHANGE_ACCOUNT_TYPE.request(() => Api.makeAccountStatus(id, status)).takeLatest();
}
export const USER_BILLING_ACCOUNTS = define('USER_BILLING_ACCOUNTS');

export function getUserAccounts(id) {
  return USER_BILLING_ACCOUNTS.request(() => Api.getUserAccountsData(id)).takeLatest();
}

export const GET_INVOICES_INFO = define('GET_INVOICES_INFO');

export function getInvoices(userId, page) {
  return GET_INVOICES_INFO.request(() => Api.getInvoicesData(userId, page)).takeLatest();
}

export const HIDE_INVOICE_FOR_SHIPPER = define('HIDE_INVOICE_FOR_SHIPPER');

export function hideInvoiceForShipper(id, checked) {
  return HIDE_INVOICE_FOR_SHIPPER.request(() => Api.hideInvoice(id, checked)).takeLatest();
}

export const GET_INVOICES_BALANCE = define('GET_INVOICES_BALANCE');

export function getInvoicesBalance(userId) {
  return GET_INVOICES_BALANCE.request(() => Api.getInvoicesBalanceData(userId)).takeLatest();
}
