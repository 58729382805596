import {
  GET_SCOPES, GET_SCOPE_GROUPS, GET_ROLES, GET_USERS, GET_COMPANY_ROLES,
} from '../actions/roles';

const initialState = {
  scopes: [],
  scopesStatus: '',
  scopeGroups: [],
  scopeGroupsStatus: '',
  roles: [],
  role: {},
  totalRolePages: 0,
  rolesStatus: '',
  users: {},
  usersStatus: '',
  totalUserPages: 0,
  companyRoles: [],
  companyRolesStatus: '',

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SCOPES.REQUEST: {
      return {
        ...state,
        scopesStatus: 'request',
      };
    }
    case GET_SCOPES.SUCCESS: {
      return {
        ...state,
        scopesStatus: 'ok',
        scopes: action.payload.data.scopes,
      };
    }
    case GET_SCOPES.FAIL: {
      return {
        ...state,
        scopesStatus: 'fail',
      };
    }
    case GET_SCOPE_GROUPS.REQUEST: {
      return {
        ...state,
        scopeGroupsStatus: 'request',
      };
    }
    case GET_SCOPE_GROUPS.SUCCESS: {
      return {
        ...state,
        scopeGroupsStatus: 'ok',
        scopeGroups: action.payload.data.groups,
      };
    }
    case GET_SCOPE_GROUPS.FAIL: {
      return {
        ...state,
        scopeGroupsStatus: 'fail',
      };
    }
    case GET_ROLES.REQUEST: {
      return {
        ...state,
        rolesStatus: 'request',
      };
    }
    case GET_ROLES.SUCCESS: {
      const { id, data } = action.payload;
      return {
        ...state,
        rolesStatus: 'ok',
        roles: data.roles,
        totalRolePages: data.totalPages,
        role: id ? data.roles[0] : {},
      };
    }
    case GET_ROLES.FAIL: {
      return {
        ...state,
        rolesStatus: 'fail',
      };
    }
    case GET_USERS.REQUEST: {
      return {
        ...state,
        usersStatus: 'request',
      };
    }
    case GET_USERS.SUCCESS: {
      const { data: { totalPages: totalUserPages, users }, status } = action.payload;
      return {
        ...state,
        usersStatus: 'ok',
        users: { ...state.users, [status]: { users, totalUserPages } },
        totalUserPages,
      };
    }
    case GET_USERS.FAIL: {
      return {
        ...state,
        usersStatus: 'fail',
        users: {},
      };
    }
    case GET_COMPANY_ROLES.REQUEST: {
      return {
        ...state,
        companyRolesStatus: 'request',
      };
    }
    case GET_COMPANY_ROLES.SUCCESS: {
      const { data: { roles } } = action.payload;
      return {
        ...state,
        companyRolesStatus: 'ok',
        companyRoles: roles,
      };
    }
    case GET_COMPANY_ROLES.FAIL: {
      return {
        ...state,
        companyRolesStatus: 'fail',
        companyRoles: [],
      };
    }
    default: {
      return state;
    }
  }
}
