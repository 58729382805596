import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import posthog from 'posthog-js';
import App from './App';
import store from './store';
import './posthog';
import config from './config';
import reportWebVitals from './reportWebVitals';

posthog.init(config.POSTHOG_APY_KEY, {
  api_host: config.POSTHOG_API_HOST,
  autocapture: false,
  session_recording: { maskAllInputs: false },
});

Modal.setAppElement(document.body);
posthog.startSessionRecording();
ReactDOM.render((
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
), document.getElementById('root'));

reportWebVitals();
