import { SIGN_UP_REQUEST_DATA, RESET_SIGN_UP_REQUEST_DATA, GET_LEAD_INFO } from '../actions/signUp';

const initSignUpRequestData = {
  contactInfo: {
    fName: '',
    lName: '',
    companyName: '',
    email: '',
    phone: '',
    password: '',
  },
  shippingNeeds: {
    token: '',
    companyState: '',
    companyModeTypes: [],
    companyAverageFTL: '',
    companyEquipmentTypes: [],
    callbackUrl: `${window.location.origin}/sign-up/account-creation`,
  },
  accountCreation: { token: '', password: '' },
};

const initialState = {
  signUpRequestData: initSignUpRequestData,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_UP_REQUEST_DATA: {
      const { step, key, value } = action.payload;
      const signUpRequestData = {
        ...state.signUpRequestData,
        [step]: {
          ...state.signUpRequestData[step],
          [key]: value,
        },
      };
      return {
        ...state,
        signUpRequestData,
      };
    }
    case RESET_SIGN_UP_REQUEST_DATA: {
      return {
        ...state,
        signUpRequestData: initSignUpRequestData,
      };
    }
    case GET_LEAD_INFO.SUCCESS: {
      const { data: { data } } = action.payload;
      data.phone = data.phone.replace('+1', '');
      return {
        ...state,
        signUpRequestData: { ...state.signUpRequestData, contactInfo: data },
      };
    }
    default: {
      return state;
    }
  }
}
