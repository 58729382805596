import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_SCOPES = define('GET_SCOPES');

export function getScopesRequest() {
  return GET_SCOPES.request(() => Api.getScopes());
}

export const CREATE_ROLE = define('CREATE_ROLE');

export function createRoleRequest(name, scopes) {
  return CREATE_ROLE.request(() => Api.createRole(name, scopes));
}

export const UPDATE_ROLE = define('UPDATE_ROLE');

export function updateRoleRequest(id, name, scopes) {
  return UPDATE_ROLE.request(() => Api.updateRole(id, name, scopes));
}

export const GET_SCOPE_GROUPS = define('GET_SCOPE_GROUPS');

export function getScopeGroupsRequest() {
  return GET_SCOPE_GROUPS.request(() => Api.getScopeGroups());
}

export const CREATE_USER = define('CREATE_USER');

export function createUserRequest(data) {
  return CREATE_USER.request(() => Api.createUser(data));
}

export const GET_USERS = define('GET_USERS');

export function getUsersRequest(page, params) {
  return GET_USERS.request(() => Api.getUsers(page, params)).onSuccess((p) => {
    p.status = params.status;
    return p;
  });
}

export const UPDATE_USER_STATUS = define('UPDATE_USER_STATUS');

export function updateUserStatusRequest(userId, status, roleId) {
  return UPDATE_USER_STATUS.request(() => Api.updateUserStatus(userId, status, roleId));
}

export const DELETE_ROLE = define('DELETE_ROLE');

export function deleteRoleRequest(id) {
  return DELETE_ROLE.request(() => Api.deleteRole(id));
}

export const GET_COMPANY_ROLES = define('GET_COMPANY_ROLES');

export function getCompanyRolesRequest() {
  return GET_COMPANY_ROLES.request(() => Api.getCompanyRoles());
}

export const GET_ROLES = define('GET_ROLES');

export function getRolesRequest(id, page) {
  return GET_ROLES.request(() => Api.getRoles(id, page)).onSuccess((p) => {
    p.id = id;
    return p;
  });
}
