import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GENERATE_API_KEY = define('GENERATE_API_KEY');

export function generateApiKeyRequest() {
  return GENERATE_API_KEY.request(() => Api.generateApiKey());
}

export const UPDATE_API_KEY = define('UPDATE_API_KEY');

export function updateApiKeyRequest() {
  return UPDATE_API_KEY.request(() => Api.updateApiKey());
}

export const GET_API_KEY = define('GET_API_KEY');

export function getApiKeyRequest() {
  return GET_API_KEY.request(() => Api.getApiKey());
}

export const SET_WEBHOOK_URL = define('SET_WEBHOOK_URL');

export function setWebhookUrlRequest(url) {
  return SET_WEBHOOK_URL.request(() => Api.setWebhookUrl(url));
}
export const DELETE_WEBHOOK_URL = define('DELETE_WEBHOOK_URL');

export function deleteWebhookUrlRequest() {
  return DELETE_WEBHOOK_URL.request(() => Api.deleteWebhookUrl());
}
