import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const CREATE_LEAD = define('CREATE_LEAD');

export function createLeadRequest(data) {
  return CREATE_LEAD.request(() => Api.createLead(data));
}

export const UPDATE_LEAD = define('UPDATE_LEAD');

export function updateLeadRequest(data) {
  return UPDATE_LEAD.request(() => Api.updateLead(data));
}

export const GET_LEADS = define('GET_LEADS');

export function getLeadsRequest(params) {
  return GET_LEADS.request(() => Api.getLeads(params)).takeLatest();
}

export const GET_LEAD = define('GET_LEAD');

export function getLeadRequest(id) {
  return GET_LEAD.request(() => Api.getLead(id));
}

export const ADD_LEAD_MEMBER = define('ADD_LEAD_MEMBER');

export function addLeadMemberRequest(data) {
  return ADD_LEAD_MEMBER.request(() => Api.addLeadMember(data));
}

export const UPDATE_LEAD_MEMBER = define('UPDATE_LEAD_MEMBER');

export function updateLeadMemberRequest(data) {
  return UPDATE_LEAD_MEMBER.request(() => Api.updateLeadMember(data));
}

export const GET_LEAD_MEMBERS = define('GET_LEAD_MEMBERS');

export function getLeadMembersRequest(params) {
  return GET_LEAD_MEMBERS.request(() => Api.getLeadMembers(params));
}

export const GET_LEAD_MEMBER = define('GET_LEAD_MEMBER');

export function getLeadMemberRequest(id, leadId) {
  return GET_LEAD_MEMBER.request(() => Api.getLeadMember(id, leadId));
}

export const GET_LEAD_LANES = define('GET_LEAD_LANES');

export function getLeadLanesRequest(params) {
  return GET_LEAD_LANES.request(() => Api.getLeadLanes(params));
}

export const GET_LEAD_LANE = define('GET_LEAD_LANE');

export function getLeadLaneRequest(id, leadId) {
  return GET_LEAD_LANE.request(() => Api.getLeadLane(id, leadId));
}

export const CREATE_LEAD_LANE = define('CREATE_LEAD_LANE');

export function createLeadLaneRequest(data) {
  return CREATE_LEAD_LANE.request(() => Api.createLeadLane(data));
}

export const UPDATE_LEAD_LANE = define('UPDATE_LEAD_LANE');

export function updateLeadLaneRequest(data) {
  return UPDATE_LEAD_LANE.request(() => Api.updateLeadLane(data));
}

export const DELETE_LEAD_LANE = define('DELETE_LEAD_LANE');

export function deleteLeadLaneRequest(id) {
  return DELETE_LEAD_LANE.request(() => Api.deleteLeadLane(id));
}

export const UPDATE_LEAD_MEMBER_STATUS = define('UPDATE_LEAD_MEMBER_STATUS');

export function updateLeadMemberStatusRequest(id, status) {
  return UPDATE_LEAD_MEMBER_STATUS.request(() => Api.updateLeadMemberStatus(id, status));
}

export const UPDATE_LEAD_SUBSCRIPTION_STATUS = define('UPDATE_LEAD_SUBSCRIPTION_STATUS');

export function updateLeadSubscriptionStatusRequest(data) {
  return UPDATE_LEAD_SUBSCRIPTION_STATUS.request(() => Api.updateLeadSubscriptionStatus(data));
}
