import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const CREATE_SUBSCRIPTION_PLAN = define('CREATE_SUBSCRIPTION_PLAN');

export function createSubscriptionPlanRequest(data) {
  return CREATE_SUBSCRIPTION_PLAN.request(() => Api.createSubscriptionPlan(data));
}

export const UPDATE_SUBSCRIPTION_PLAN = define('UPDATE_SUBSCRIPTION_PLAN');

export function updateSubscriptionPlanRequest(data) {
  return UPDATE_SUBSCRIPTION_PLAN.request(() => Api.updateSubscriptionPlan(data));
}

export const UPDATE_SUBSCRIPTION_PLAN_STATUS = define('UPDATE_SUBSCRIPTION_PLAN_STATUS');

export function updateSubscriptionPlanStatusRequest(data) {
  return UPDATE_SUBSCRIPTION_PLAN_STATUS.request(() => Api.updateSubscriptionPlanStatus(data));
}

export const GET_SUBSCRIPTION_PLANS = define('GET_SUBSCRIPTION_PLANS');

export function getSubscriptionPlansRequest(companyId) {
  return GET_SUBSCRIPTION_PLANS.request(() => Api.getSubscriptionPlans(companyId));
}

export const GET_CURRENT_SUBSCRIPTION_PLAN = define('GET_CURRENT_SUBSCRIPTION_PLAN');

export function getCurrentSubscriptionPlanRequest(companyId) {
  return GET_CURRENT_SUBSCRIPTION_PLAN.request(() => Api.getCurrentSubscriptionPlan(companyId));
}

export const MARK_INVOICE_PAID = define('MARK_INVOICE_PAID');

export function markInvoicePaidRequest(id, companyId) {
  return MARK_INVOICE_PAID.request(() => Api.markInvoicePaid(id, companyId));
}

export const REMOVE_SUBSCRIPTION_DATA = 'REMOVE_SUBSCRIPTION_DATA';

export function removeSubscriptionData() {
  return {
    type: REMOVE_SUBSCRIPTION_DATA,
    payload: {},
  };
}
