import {
  GET_PLAID_LINK_TOKEN,
  EXCHANGE_PLAID_TOKEN,
  CHECK_PLAID_ACCOUNT,
  BANK_ACCOUNT_INFO,
  USER_BILLING_ACCOUNTS,
  GET_INVOICES_INFO, GET_INVOICES_BALANCE, CHECK_USER_PLAID_ACCOUNT, HIDE_INVOICE_FOR_SHIPPER,
} from '../actions/plaid';

const initialState = {
  plaidLinkToken: '',
  plaidLinkTokenStatus: '',
  exchangePlaidTokenStatus: '',
  plaidAccountStatus: '',
  userPlaidAccount: false,
  account: {},
  userBankAccounts: {},
  invoices: {},
  invoicesBalance: {},
  bankAccountStatus: '',
  userBankAccountsStatus: '',
  invoicesStatus: '',
  hideInvoiceStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLAID_LINK_TOKEN.REQUEST: {
      return {
        ...state,
        plaidLinkToken: '',
        plaidLinkTokenStatus: 'request',
      };
    }
    case GET_PLAID_LINK_TOKEN.SUCCESS: {
      return {
        ...state,
        plaidLinkTokenStatus: 'ok',
        plaidLinkToken: action.payload.data.link_token,
      };
    }
    case GET_PLAID_LINK_TOKEN.FAIL: {
      return {
        ...state,
        plaidLinkTokenStatus: 'fail',
      };
    }
    case EXCHANGE_PLAID_TOKEN.REQUEST: {
      return {
        ...state,
        exchangePlaidTokenStatus: 'request',
      };
    }
    case EXCHANGE_PLAID_TOKEN.SUCCESS: {
      return {
        ...state,
        exchangePlaidTokenStatus: 'ok',
        plaidLinkToken: '',
      };
    }
    case EXCHANGE_PLAID_TOKEN.FAIL: {
      return {
        ...state,
        exchangePlaidTokenStatus: 'fail',
        plaidLinkToken: '',
      };
    }
    case CHECK_PLAID_ACCOUNT.REQUEST: {
      return {
        ...state,
        plaidAccountStatus: 'request',
      };
    }
    case CHECK_PLAID_ACCOUNT.SUCCESS: {
      return {
        ...state,
        plaidAccountStatus: 'ok',
        plaidAccount: action.payload.data.hasVerifiedAccount,
      };
    }
    case CHECK_PLAID_ACCOUNT.FAIL: {
      return {
        ...state,
        plaidAccountStatus: 'fail',
        plaidAccount: false,
      };
    }
    case CHECK_USER_PLAID_ACCOUNT.REQUEST: {
      return {
        ...state,
        plaidAccountStatus: 'request',
      };
    }
    case CHECK_USER_PLAID_ACCOUNT.SUCCESS: {
      return {
        ...state,
        plaidAccountStatus: 'ok',
        userPlaidAccount: action.payload.data.hasVerifiedAccount,
      };
    }
    case CHECK_USER_PLAID_ACCOUNT.FAIL: {
      return {
        ...state,
        plaidAccountStatus: 'fail',
        plaidAccount: false,
      };
    }
    case BANK_ACCOUNT_INFO.REQUEST: {
      return {
        ...state,
        bankAccountStatus: 'request',
      };
    }
    case BANK_ACCOUNT_INFO.SUCCESS: {
      return {
        ...state,
        account: action.payload.data.account,
        bankAccountStatus: 'ok',
      };
    }
    case BANK_ACCOUNT_INFO.FAIL: {
      return {
        ...state,
        account: action.payload.data.account,
        bankAccountStatus: 'fail',
      };
    }
    case USER_BILLING_ACCOUNTS.REQUEST: {
      return {
        ...state,
        userBankAccountsStatus: 'request',
      };
    }
    case USER_BILLING_ACCOUNTS.SUCCESS: {
      return {
        ...state,
        userBankAccountsStatus: 'ok',
        userBankAccounts: action.payload.data.account,
      };
    }
    case USER_BILLING_ACCOUNTS.FAIL: {
      return {
        ...state,
        userBankAccountsStatus: 'fail',
        userBankAccounts: action.payload.data.account,
      };
    }
    case GET_INVOICES_INFO.REQUEST: {
      return {
        ...state,
        invoicesStatus: 'request',
      };
    }
    case GET_INVOICES_INFO.SUCCESS: {
      return {
        ...state,
        invoicesStatus: 'ok',
        invoices: action.payload.data,
      };
    }
    case GET_INVOICES_INFO.FAIL: {
      return {
        ...state,
        invoices: {},
        invoicesStatus: 'fail',
      };
    }
    case HIDE_INVOICE_FOR_SHIPPER.REQUEST: {
      return {
        ...state,
        hideInvoiceStatus: 'request',
      };
    }
    case HIDE_INVOICE_FOR_SHIPPER.SUCCESS: {
      return {
        ...state,
        hideInvoiceStatus: 'success',
      };
    }
    case HIDE_INVOICE_FOR_SHIPPER.FAIL: {
      return {
        ...state,
        hideInvoiceStatus: 'fail',
      };
    }
    case GET_INVOICES_BALANCE.REQUEST: {
      return {
        ...state,
      };
    }
    case GET_INVOICES_BALANCE.SUCCESS: {
      return {
        ...state,
        invoicesBalance: action.payload.data,
      };
    }
    case GET_INVOICES_BALANCE.FAIL: {
      return {
        ...state,
        invoicesBalance: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
