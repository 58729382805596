import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GOOGLE_AUTOCOMPLETE = define('GOOGLE_AUTOCOMPLETE');

export function googleAutocompleteRequest(s) {
  return GOOGLE_AUTOCOMPLETE.request(() => Api.googleAutocomplete(s));
}

export const SHIPMENT_ESTIMATION = define('SHIPMENT_ESTIMATION');

export function shipmentEstimationRequest(data, type = 'create') {
  return SHIPMENT_ESTIMATION.request(() => Api.shipmentEstimation(data)).onSuccess((p) => {
    p.requestData = data;
    p.type = type;
    return p;
  }).takeLatest();
}

export const GET_PLACE_BY_ID = define('GET_PLACE_BY_ID');

export function getPlaceByIdRequest(id, key) {
  return GET_PLACE_BY_ID.request(() => Api.getPlaceById(id, key)).onSuccess((p) => {
    p.key = key;
    return p;
  });
}

export const GET_ROUTE_DATA = define('GET_ROUTE_DATA');

export function getRouteDataRequest(data) {
  return GET_ROUTE_DATA.request(() => Api.getRouteData(data));
}

export const CREATE_SHIPMENT_REQUEST_DATA = 'CREATE_SHIPMENT_REQUEST_DATA';

export function createShipmentRequestData(step, key, value) {
  return {
    type: CREATE_SHIPMENT_REQUEST_DATA,
    payload: { step, key, value },
  };
}

export const CREATE_SHIPMENT_REQUEST_DATA_STEP_ONE = 'CREATE_SHIPMENT_REQUEST_DATA_STEP_ONE';

export function createShipmentRequestDataStepOne(key, value) {
  return {
    type: CREATE_SHIPMENT_REQUEST_DATA_STEP_ONE,
    payload: { key, value },
  };
}

export const CREATE_SHIPMENT_REQUEST_DATA_STEP_TWO = 'CREATE_SHIPMENT_REQUEST_DATA_STEP_TWO';

export function createShipmentRequestDataStepTwo(key, value) {
  return {
    type: CREATE_SHIPMENT_REQUEST_DATA_STEP_TWO,
    payload: { key, value },
  };
}

export const CREATE_SHIPMENT_REQUEST_DATA_STEP_THREE = 'CREATE_SHIPMENT_REQUEST_DATA_STEP_THREE';

export function createShipmentRequestDataStepThree(key, value) {
  return {
    type: CREATE_SHIPMENT_REQUEST_DATA_STEP_THREE,
    payload: { key, value },
  };
}

export const CREATE_SHIPMENT = define('CREATE_SHIPMENT');

export function createShipmentRequest(data, token) {
  return CREATE_SHIPMENT.request(() => Api.createShipment(data, token));
}

export const GET_SHIPMENTS = define('GET_SHIPMENTS');

export function getShipmentsRequest(status, limit, userType, shipperId, selectedUserType, s, page, refresh = true) {
  return GET_SHIPMENTS.request(() => Api.getShipments(status, limit, userType, shipperId, selectedUserType, s, page, refresh))
    .onRequest((p) => {
      p.refresh = refresh;
      return p;
    })
    .onSuccess((p) => {
      p.status = status;
      return p;
    }).takeLatest();
}

export const GET_SHIPMENT_BY_ID = define('GET_SHIPMENT_BY_ID');

export function getShipmentByIdRequest(id) {
  return GET_SHIPMENT_BY_ID.request(() => Api.getShipmentById(id));
}

export const GET_USERS_COMPANIES = define('GET_USERS_COMPANIES');

export function getCompanies(s, page) {
  return GET_USERS_COMPANIES.request(() => Api.getUserCompanies(s, page)).onSuccess((p) => {
    p.search = s;
    return p;
  }).takeLatest();
}

export const CANCEL_SHIPMENT = define('CANCEL_SHIPMENT');

export function cancelShipmentRequest(id) {
  return CANCEL_SHIPMENT.request(() => Api.cancelShipment(id));
}

export const LOAD_ASSIGN = define('LOAD_ASSIGN');

export function loadsAssignRequest(data) {
  return LOAD_ASSIGN.request(() => Api.loadsAssign(data));
}

export const SEARCH_MC = define('SEARCH_MC');

export function searchMcRequest(data, type) {
  return SEARCH_MC.request(() => Api.searchMc(data, type));
}

export const UPDATE_SHIPMENT = define('UPDATE_SHIPMENT');

export function updateShipmentRequest(data) {
  return UPDATE_SHIPMENT.request(() => Api.updateShipment(data));
}

export const UPDATE_STATUS = define('UPDATE_STATUS');

export function updateStatusRequest(data) {
  return UPDATE_STATUS.request(() => Api.updateStatus(data));
}

export const REMOVE_SHIPMENT_REQUEST_DATA = 'REMOVE_SHIPMENT_REQUEST_DATA';

export function removeShipmentRequestData() {
  return {
    type: REMOVE_SHIPMENT_REQUEST_DATA,
    payload: {},
  };
}

export const UPDATE_PAYMENT_STATUS = define('UPDATE_PAYMENT_STATUS');

export function updatePaymentStatusRequest(data) {
  return UPDATE_PAYMENT_STATUS.request(() => Api.updatePaymentStatus(data));
}

export const GET_SHIPPERS_LIST = define('GET_SHIPPERS_LIST');

export function getShippersListRequest(s) {
  return GET_SHIPPERS_LIST.request(() => Api.getShippersList(s));
}

export const LOAD_ASSIGN_PHONE = define('LOAD_ASSIGN_PHONE');

export function loadsAssignPhoneRequest(data) {
  return LOAD_ASSIGN_PHONE.request(() => Api.assignPhone(data));
}

export const GET_NEW_SMS = define('GET_NEW_SMS');

export function getNewSmsRequest(id) {
  return GET_NEW_SMS.request(() => Api.getNewSms(id));
}

export const GET_NEW_SMS_NO = define('GET_NEW_SMS_NO');

export function getNewSmsNoRequest(id) {
  return GET_NEW_SMS_NO.request(() => Api.getNewSmsNo(id)).onSuccess((p) => {
    p.shipmentId = id;
    return p;
  });
}

export const POST_SHIPMENT = define('POST_SHIPMENT');

export function postShipmentRequest(id) {
  return POST_SHIPMENT.request(() => Api.postShipment(id));
}

export const UNPOST_SHIPMENT = define('UNPOST_SHIPMENT');

export function unpostShipmentRequest(id) {
  return UNPOST_SHIPMENT.request(() => Api.unpostShipment(id));
}

export const UPDATE_SHIPMENT_HISTORY = define('UPDATE_SHIPMENT_HISTORY');

export function updateShipmentHistoryRequest(data) {
  return UPDATE_SHIPMENT_HISTORY.request(() => Api.updateShipmentHistory(data));
}

export const GET_SHIPMENT_UPDATE_HISTORY = define('GET_SHIPMENT_UPDATE_HISTORY');

export function getShipmentUpdateHistoryRequest(id, page) {
  return GET_SHIPMENT_UPDATE_HISTORY.request(() => Api.getShipmentUpdateHistory(id, page));
}

export const ADD_PICKUP_DATA = 'ADD_PICKUP_DATA';

export function addPickupData(order) {
  return {
    type: ADD_PICKUP_DATA,
    payload: { order },
  };
}

export const ADD_DROPOFF_DATA = 'ADD_DROPOFF_DATA';

export function addDropOffData(order) {
  return {
    type: ADD_DROPOFF_DATA,
    payload: { order },
  };
}

export const COLLECT_PRICE_DATA = 'COLLECT_PRICE_DATA';

export function collectPriceData(price) {
  return {
    type: COLLECT_PRICE_DATA,
    payload: { price },
  };
}

export const LOAD_BASED_AUTOCOMPLETE = define('LOAD_BASED_AUTOCOMPLETE');

export function loadBasedAutocompleteRequest(shipperId, targetField, s) {
  return LOAD_BASED_AUTOCOMPLETE.request(() => Api.loadBasedAutocomplete(shipperId, targetField, s));
}

export const LOAD_AUTOFILL = define('LOAD_AUTOFILL');

export function loadAutofillRequest(shipperId, origin, destination, equipment) {
  return LOAD_AUTOFILL.request(() => Api.loadAutofill(shipperId, origin, destination, equipment));
}

export const SET_LANE_DATA = 'SET_LANE_DATA';

export function setLaneDate(data) {
  return {
    type: SET_LANE_DATA,
    payload: { data },
  };
}
