import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const CREATE_DISCOUNT = define('CREATE_DISCOUNT');

export function createDiscountRequest(data) {
  return CREATE_DISCOUNT.request(() => Api.createDiscount(data));
}

export const CREATE_DISCOUNT_STATE = define('CREATE_DISCOUNT_STATE');

export function createDiscountStateRequest(data) {
  return CREATE_DISCOUNT_STATE.request(() => Api.createDiscountState(data));
}

export const UPDATE_DISCOUNT = define('UPDATE_DISCOUNT');

export function updateDiscountRequest(data) {
  return UPDATE_DISCOUNT.request(() => Api.updateDiscount(data));
}
export const UPDATE_DISCOUNT_STATE = define('UPDATE_DISCOUNT_STATE');

export function updateDiscountStateRequest(data) {
  return UPDATE_DISCOUNT_STATE.request(() => Api.updateDiscountState(data));
}

export const UPDATE_GLOBAL_DISCOUNT = define('UPDATE_GLOBAL_DISCOUNT');

export function updateGlobalDiscountRequest(percentage) {
  return UPDATE_GLOBAL_DISCOUNT.request(() => Api.updateGlobalDiscount(percentage));
}

export const GET_DISCOUNTS = define('GET_DISCOUNTS');

export function getDiscountsRequest(page) {
  return GET_DISCOUNTS.request(() => Api.getDiscounts(page));
}

export const GET_DISCOUNTS_STATE = define('GET_DISCOUNTS_STATE');

export function getDiscountsStateRequest(page) {
  return GET_DISCOUNTS_STATE.request(() => Api.getDiscountsState(page));
}

export const DELETE_DISCOUNT = define('DELETE_DISCOUNT');

export function deleteDiscountRequest(id) {
  return DELETE_DISCOUNT.request(() => Api.deleteDiscount(id));
}

export const DELETE_DISCOUNT_STATE = define('DELETE_DISCOUNT_STATE');

export function deleteDiscountStateRequest(id) {
  return DELETE_DISCOUNT_STATE.request(() => Api.deleteDiscountState(id));
}

export const GET_STATES = define('GET_STATES');

export function getStatesRequest() {
  return GET_STATES.request(() => Api.getStates());
}
