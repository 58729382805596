import _ from 'lodash';
import moment from 'moment';
import {
  GET_PLACE_BY_ID,
  SHIPMENT_ESTIMATION,
  CREATE_SHIPMENT,
  GET_SHIPMENTS,
  GET_SHIPMENT_BY_ID,
  CREATE_SHIPMENT_REQUEST_DATA_STEP_ONE,
  CREATE_SHIPMENT_REQUEST_DATA_STEP_TWO,
  CREATE_SHIPMENT_REQUEST_DATA_STEP_THREE,
  LOAD_ASSIGN,
  GET_USERS_COMPANIES,
  CREATE_SHIPMENT_REQUEST_DATA,
  REMOVE_SHIPMENT_REQUEST_DATA,
  GET_SHIPPERS_LIST,
  GET_ROUTE_DATA, GET_NEW_SMS_NO,
  ADD_PICKUP_DATA,
  GET_SHIPMENT_UPDATE_HISTORY, ADD_DROPOFF_DATA,
  COLLECT_PRICE_DATA,
  LOAD_BASED_AUTOCOMPLETE,
  LOAD_AUTOFILL,
  SET_LANE_DATA,
} from '../actions/shipments';
import { DELETE_USER, USER_PROFILE_CONFIRM, USER_PROFILE_UPDATE } from '../actions/account';

const initShipmentRequestDataStepOne = {
  equipment: '',
  dropOffLocation: [],
  pickUpLocation: [],
  dropOffDate: '',
  pickUpDate: '',
  currentPickUpLocation: [''],
  currentDropOffLocation: [''],
};
const initPickUpData = {
  pickUpFacilityName: '',
  pickUpFirstComeFirstServer: '',
  pickUpNoAppointmentDate: '',
  pickUpAppointmentStartDate: '',
  pickUpAppointmentEndDate: '',
  pickUpNumber: '',
  pickUpContactInfo: { phone: '' },
  pickUpOrder: 1,
};
const initDropOffData = {
  dropOffOrder: 1,
  dropOffFacilityName: '',
  dropOffAppointmentStartDate: '',
  dropOffAppointmentEndDate: '',
  dropOffFirstComeFirstServer: '',
  dropOffNoAppointmentDate: '',
  dropOffContactInfo: { phone: '' },
  dropOffNumber: '',
};
const initShipmentRequestDataStepTwo = {
  pickUp: [],
  dropOff: [],
};

const initShipmentRequestDataStepThree = {
  customerReferenceNumber: '',
  commodity: '',
  weight: '',
  qty: '',
  loadNote: '',
  preCoolTemperature: '',
  minTrailerTemperature: '',
  maxTrailerTemperature: '',
  operatingInstructions: '',
  trailerWashout: false,
  packageType: '',
};

const initialState = {
  placesData: { pickUpLocation: [], dropOffLocation: [] },
  shipmentEstimation: {},
  shipmentEstimationStatus: '',
  shipment: {},
  shipmentStatus: '',
  shipmentsList: {},
  shipmentsCount: {},
  shipmentsListStatus: '',
  estimationRequestData: {},
  companies: [],
  companiesStatus: '',
  searchedCompanies: [],
  shippersList: [],
  shippersListStatus: '',
  routeData: {},
  routeDataStatus: '',
  shipmentRefreshStatus: false,
  shipmentRequestDataStepOne: initShipmentRequestDataStepOne,
  shipmentRequestDataStepTwo: initShipmentRequestDataStepTwo,
  shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
  shipmentRequestDataStepOnePrev: {},
  shipmentRequestDataStepTwoPrev: {},
  shipmentRequestDataStepThreePrev: {},
  canNotifyShipments: [],
  totalPages: 0,
  companiesTotalPage: 0,
  deletedUserId: null,
  updateHistory: {},
  updateHistoryStatus: '',
  priceData: [],
  loadBasedAutocomplete: [],
  loadBasedAutocompleteStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ASSIGN.REQUEST: {
      return {
        ...state,
      };
    }
    case LOAD_ASSIGN.SUCCESS: {
      return {
        ...state,
      };
    }
    case LOAD_ASSIGN.FAIL: {
      return {
        ...state,
      };
    }
    case GET_ROUTE_DATA.REQUEST: {
      return {
        ...state,
        routeDataStatus: 'request',
        routeData: {},
      };
    }
    case GET_ROUTE_DATA.SUCCESS: {
      return {
        ...state,
        routeDataStatus: 'ok',
        routeData: action.payload.data,
      };
    }
    case GET_ROUTE_DATA.FAIL: {
      return {
        ...state,
        routeDataStatus: 'fail',
        routeData: {},
      };
    }
    case GET_USERS_COMPANIES.REQUEST: {
      return {
        ...state,
        companiesStatus: 'request',
      };
    }
    case USER_PROFILE_CONFIRM.SUCCESS:
    case USER_PROFILE_UPDATE.SUCCESS: {
      const { data, id } = action.payload;
      let { companies, searchedCompanies } = state;
      companies = companies.map((c) => {
        if (c.admins[0] === id) {
          return data.companies;
        }
        return c;
      });
      searchedCompanies = searchedCompanies.map((c) => {
        if (c.admins[0] === id) {
          return data.companies;
        }
        return c;
      });
      return {
        ...state,
        companies,
      };
    }
    case DELETE_USER.SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        deletedUserId: id,
      };
    }
    case GET_USERS_COMPANIES.SUCCESS: {
      const { search } = action.payload;
      const { deletedUserId } = state;
      let { companies } = state;
      let searchedCompanies = [];
      if (search) {
        searchedCompanies = action.payload.data.companies;
      } else {
        companies = _.uniqBy([...companies, ...action.payload.data.companies], '_id');
      }
      if (deletedUserId) {
        companies = companies.filter((d) => d.userId._id !== deletedUserId);
        searchedCompanies = searchedCompanies.filter((d) => d.userId._id !== deletedUserId);
      }
      return {
        ...state,
        companies,
        searchedCompanies,
        companiesTotalPage: action.payload.data.totalPages,
        companiesStatus: 'ok',

      };
    }
    case ADD_PICKUP_DATA: {
      const shipmentRequestDataStepTwo = { ...state.shipmentRequestDataStepTwo };
      shipmentRequestDataStepTwo.pickUp = [...shipmentRequestDataStepTwo.pickUp, {
        ...initPickUpData,
        pickUpContactInfo: { ...initPickUpData.pickUpContactInfo },
        pickUpOrder: action.payload.order,
      }];
      return {
        ...state,
        shipmentRequestDataStepTwo,
      };
    }
    case ADD_DROPOFF_DATA: {
      const shipmentRequestDataStepTwo = { ...state.shipmentRequestDataStepTwo };
      shipmentRequestDataStepTwo.dropOff = [...shipmentRequestDataStepTwo.dropOff, {
        ...initDropOffData,
        dropOffContactInfo: { ...initPickUpData.dropOffContactInfo },
        dropOffOrder: action.payload.order,
      }];
      return {
        ...state,
        shipmentRequestDataStepTwo,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA: {
      const { step, key, value } = action.payload;
      const shipmentRequestData = {
        ...state.shipmentRequestData,
        [step]: {
          ...state.shipmentRequestData[step],
          [key]: value,
        },
      };
      return {
        ...state,
        shipmentRequestData,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA_STEP_ONE: {
      const { key, value } = action.payload;
      const shipmentRequestDataStepOne = { ...state.shipmentRequestDataStepOne };
      _.set(shipmentRequestDataStepOne, key, value);
      return {
        ...state,
        shipmentRequestDataStepOne,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA_STEP_TWO: {
      const { key, value } = action.payload;
      const shipmentRequestDataStepTwo = { ...state.shipmentRequestDataStepTwo };
      _.set(shipmentRequestDataStepTwo, key, value);
      return {
        ...state,
        shipmentRequestDataStepTwo,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA_STEP_THREE: {
      const { key, value } = action.payload;
      const shipmentRequestDataStepThree = { ...state.shipmentRequestDataStepThree };
      _.set(shipmentRequestDataStepThree, key, value);
      return {
        ...state,
        shipmentRequestDataStepThree,
      };
    }
    case GET_PLACE_BY_ID.SUCCESS: {
      const { key, data: { data: { place } } } = action.payload;
      let placesData;
      if (!_.isEmpty(state.placesData[key])) {
        placesData = { ...state.placesData, [key]: [...state.placesData[key], place] };
      } else {
        placesData = { ...state.placesData, [key]: [place] };
      }

      return {
        ...state,
        placesData,
      };
    }
    case SHIPMENT_ESTIMATION.REQUEST: {
      return {
        ...state,
        shipmentEstimationStatus: 'request',
      };
    }
    case SHIPMENT_ESTIMATION.SUCCESS: {
      const { data, requestData, type } = action.payload;
      const estimationRequestData = { ...state.estimationRequestData };
      const shipmentRequestDataStepOne = { ...state.shipmentRequestDataStepOne };
      if (type === 'create' && (!_.isEqual(estimationRequestData.origin, data?.origin.map((o) => o.place?.place_id).join(','))
        || !_.isEqual(estimationRequestData.destination, data?.destination.map((d) => d.place?.place_id).join(',')))) {
        shipmentRequestDataStepOne.dropOffDate = '';
        shipmentRequestDataStepOne.pickUpDate = '';
        shipmentRequestDataStepOne.pickUpDates = [];
        shipmentRequestDataStepOne.dropOffDates = [];
      }
      return {
        ...state,
        shipmentEstimation: data,
        shipmentEstimationStatus: 'ok',
        estimationRequestData: requestData,
        shipmentRequestDataStepOne,
      };
    }
    case SHIPMENT_ESTIMATION.FAIL: {
      return {
        ...state,
        shipmentEstimationStatus: 'fail',
        shipmentEstimation: {},
      };
    }
    case CREATE_SHIPMENT.SUCCESS: {
      return {
        ...state,
        shipmentRequestDataStepOne: initShipmentRequestDataStepOne,
        shipmentRequestDataStepTwo: {
          initShipmentRequestDataStepTwo,
          pickUp: [],
          dropOff: [],
        },
        shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
        shipmentEstimation: {},
        placesData: {},
        routeData: {},
      };
    }
    case CREATE_SHIPMENT.FAIL: {
      return {
        ...state,
        shipmentRequestDataStepOne: initShipmentRequestDataStepOne,
        shipmentRequestDataStepTwo: {
          initShipmentRequestDataStepTwo,
          pickUp: [],
          dropOff: [],
        },
        shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
        shipmentEstimation: {},
        placesData: {},
        routeData: {},
      };
    }
    case GET_SHIPMENTS.REQUEST: {
      return {
        ...state,
        shipmentsListStatus: 'request',
        shipmentRefreshStatus: action.payload.refresh,
      };
    }
    case GET_SHIPMENTS.SUCCESS: {
      const { data, status } = action.payload;
      const shipmentsList = { ...state.shipmentsList };
      let shipmentsCount = { ...state.shipmentsCount };
      shipmentsCount = data.counts;
      if (!status) {
        shipmentsList.all = data;
      } else {
        shipmentsList[status] = data;
      }
      return {
        ...state,
        shipmentsList,
        shipmentsListStatus: 'ok',
        shipmentsCount,
        totalPages: data.totalPages,
        shipmentRefreshStatus: true,
      };
    }
    case GET_SHIPMENTS.FAIL: {
      return {
        ...state,
        shipmentsListStatus: 'fail',
        shipmentsList: {},
      };
    }
    case GET_SHIPMENT_BY_ID.REQUEST: {
      return {
        ...state,
        shipmentStatus: 'request',
      };
    }
    case GET_SHIPMENT_BY_ID.SUCCESS: {
      const {
        load, load: {
          equipment, dropOff: dropOffData, pickUp: pickUpData, _id, equipmentDetails, customerReferenceNumber,
          commodity, weight, qty, loadNote, packageType, tarp, driverAssist,
        },
      } = action.payload.data;
      const getAppointmentId = (data, key) => {
        if (data.noAppointmentDate) {
          return `${key}ZB`;
        }
        if (data.firstComeFirstServer) {
          return `${key}FCFS`;
        }
        return `${key}Scheduled`;
      };
      let shipmentRequestDataStepOne = { ...state.shipmentRequestDataStepOne };
      let shipmentRequestDataStepTwo = { ...state.shipmentRequestDataStepTwo };
      let shipmentRequestDataStepThree = { ...state.shipmentRequestDataStepThree };
      shipmentRequestDataStepOne = {
        id: _id,
        equipment,
        dropOffLocation: dropOffData.map((p) => p.placeInfo?.place_id),
        currentDropOffLocation: dropOffData.map((p) => p.placeInfo?.formatted_address),
        currentPickUpLocation: pickUpData.map((p) => p.placeInfo?.formatted_address),
        pickUpLocation: pickUpData.map((p) => p.placeInfo?.place_id),
        pickUpUUID: pickUpData.map((p) => p.uuid),
        dropOffUUID: dropOffData.map((p) => p.uuid),
        dropOffDates: dropOffData.map((p) => moment.utc(p.date).format('YYYY-MM-DD')),
        pickUpDates: pickUpData.map((p) => moment.utc(p.date).format('YYYY-MM-DD')),
        teamDriving: load.teamDriving,
        updatedAt: load.updatedAt,
      };

      const pickUp = pickUpData.map((p) => ({
        pickUpFacilityName: p.facilityName,
        $uIdpickUp: getAppointmentId(p, 'pickUp'),
        pickUpFirstComeFirstServer: p.firstComeFirstServer ? 1 : 0,
        pickUpNoAppointmentDate: p.noAppointmentDate,
        pickUpAppointmentStartDate: moment.utc(p?.appointmentStartDate).format('h:mm A'),
        pickUpAppointmentEndDate: moment.utc(p?.appointmentEndDate).format('h:mm A'),
        pickUpNumber: p.number,
        pickUpContactInfo: p.contactInfo,
        pickUpLocation: p.placeInfo?.place_id,
        customerReferenceNumber: p.customerReferenceNumber || customerReferenceNumber,
        commodity: p.commodity || commodity,
        weight: p.weight || weight,
        qty: p.qty || qty,
        packageType: p.packageType || packageType,
        pickUpOrder: p.order || 1,
        pickUpDate: moment(p.date).format('YYYY-MM-DD'),
      }));
      const dropOff = dropOffData.map((d) => ({
        dropOffFacilityName: d.facilityName || load.dropOff.facilityName,
        $uIddropOff: getAppointmentId(d, 'dropOff'),
        dropOffFirstComeFirstServer: (d.firstComeFirstServer || load.dropOff.firstComeFirstServer) ? 1 : 0,
        dropOffNoAppointmentDate: d.noAppointmentDate || load.dropOff.noAppointmentDate,
        dropOffAppointmentStartDate: moment.utc(d?.appointmentStartDate || load.dropOff.appointmentStartDate).format('h:mm A'),
        dropOffAppointmentEndDate: moment.utc(d?.appointmentEndDate || load.dropOff.appointmentEndDate).format('h:mm A'),
        dropOffNumber: d.number || load.dropOff.number,
        dropOffContactInfo: d.contactInfo || load.dropOff.contactInfo,
        dropOffLocation: d.placeInfo?.place_id || load.dropOff.placeInfo?.place_id,
        dropOffOrder: d.order || 1,
        customerReferenceNumber: d.customerReferenceNumber || load.dropOff.customerReferenceNumber,
        commodity: d.commodity || load.dropOff.commodity,
        weight: d.weight || load.dropOff.weight,
        qty: d.qty,
        packageType: d.packageType,
        dropOffDate: moment(d.date).format('YYYY-MM-DD'),
      }));

      shipmentRequestDataStepTwo = {
        pickUp,
        dropOff,
      };
      shipmentRequestDataStepThree = {
        customerReferenceNumber,
        commodity,
        weight,
        qty,
        loadNote,
        preCoolTemperature: equipmentDetails.preCoolTemperature,
        minTrailerTemperature: equipmentDetails.minTrailerTemperature,
        maxTrailerTemperature: equipmentDetails.maxTrailerTemperature,
        operatingInstructions: equipmentDetails.operatingInstructions,
        trailerWashout: equipmentDetails.trailerWashout,
        packageType,
        tarp,
        driverAssist,
      };

      const shipmentRequestDataStepOnePrev = _.cloneDeep(shipmentRequestDataStepOne);
      const shipmentRequestDataStepTwoPrev = _.cloneDeep(shipmentRequestDataStepTwo);
      const shipmentRequestDataStepThreePrev = _.cloneDeep(shipmentRequestDataStepThree);
      return {
        ...state,
        shipment: load,
        shipmentStatus: 'ok',
        shipmentRequestDataStepOne,
        shipmentRequestDataStepTwo,
        shipmentRequestDataStepThree,
        shipmentRequestDataStepOnePrev,
        shipmentRequestDataStepTwoPrev,
        shipmentRequestDataStepThreePrev,
      };
    }
    case LOAD_AUTOFILL.SUCCESS: {
      let shipmentRequestDataStepTwo = { ...state.shipmentRequestDataStepTwo };
      const { load } = action.payload.data;
      const pickUp = load.pickUp.map((p) => ({
        pickUpFacilityName: p.facilityName,
        pickUpContactInfo: p.contactInfo,
      }));
      const dropOff = load.dropOff.map((p) => ({
        dropOffFacilityName: p.facilityName,
        dropOffContactInfo: p.contactInfo,
      }));

      shipmentRequestDataStepTwo = {
        pickUp,
        dropOff,
      };
      return {
        ...state,
        shipmentRequestDataStepTwo,
      };
    }
    case GET_SHIPMENT_BY_ID.FAIL: {
      return {
        ...state,
        shipmentStatus: 'fail',
      };
    }
    case REMOVE_SHIPMENT_REQUEST_DATA: {
      return {
        ...state,
        shipmentRequestDataStepOne: {
          ...initShipmentRequestDataStepOne,
          pickUpLocation: [],
          dropOffLocation: [],
          currentDropOffLocation: [''],
          currentPickUpLocation: [''],
        },
        shipmentRequestDataStepTwo: {
          ...initShipmentRequestDataStepTwo,
          pickUp: [],
          dropOff: [],
        },
        shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
        shipmentEstimation: {},
        placesData: {},
        estimationRequestData: {},
        routeData: {},
        priceData: [],
      };
    }
    case GET_SHIPPERS_LIST.REQUEST: {
      return {
        ...state,
        shippersListStatus: 'request',
      };
    }
    case GET_SHIPPERS_LIST.SUCCESS: {
      return {
        ...state,
        shippersListStatus: 'ok',
        shippersList: action.payload.data.companies,
      };
    }
    case GET_SHIPPERS_LIST.FAIL: {
      return {
        ...state,
        shippersListStatus: 'fail',
        shippersList: [],
      };
    }
    case SET_LANE_DATA: {
      let shipmentRequestDataStepOne = { ...state.shipmentRequestDataStepOne };
      const { data } = action.payload;
      shipmentRequestDataStepOne = {
        equipment: data.equipment,
        dropOffLocation: [data.destination_place_id],
        currentDropOffLocation: [data.destination],
        currentPickUpLocation: [data.origin],
        pickUpLocation: [data.origin_place_id],
      };
      return {
        ...state,
        shipmentRequestDataStepOne,
      };
    }
    case GET_SHIPMENT_UPDATE_HISTORY.REQUEST: {
      return {
        ...state,
        updateHistoryStatus: 'request',
      };
    }
    case GET_SHIPMENT_UPDATE_HISTORY.SUCCESS: {
      return {
        ...state,
        updateHistoryStatus: 'ok',
        updateHistory: action.payload.data,
      };
    }
    case GET_SHIPMENT_UPDATE_HISTORY.FAIL: {
      return {
        ...state,
        updateHistoryStatus: 'fail',
        updateHistory: {},
      };
    }
    case COLLECT_PRICE_DATA: {
      return {
        ...state,
        priceData: [...state.priceData, action.payload.price],
      };
    }
    case GET_NEW_SMS_NO.SUCCESS: {
      const { shipmentId, data: { canNotify } } = action.payload;
      const canNotifyShipments = [...state.canNotifyShipments];
      const i = canNotifyShipments.findIndex((c) => c._id === shipmentId);
      if (i > -1) {
        canNotifyShipments[i] = {
          _id: shipmentId,
          canNotify,
        };
      } else {
        canNotifyShipments.push({
          _id: shipmentId,
          canNotify,
        });
      }

      return {
        ...state,
        canNotifyShipments,
      };
    }
    case LOAD_BASED_AUTOCOMPLETE.REQUEST: {
      return {
        ...state,
        loadBasedAutocompleteStatus: 'request',
      };
    }
    case LOAD_BASED_AUTOCOMPLETE.SUCCESS: {
      return {
        ...state,
        loadBasedAutocomplete: action.payload.data,
        loadBasedAutocompleteStatus: 'ok',
      };
    }
    case LOAD_BASED_AUTOCOMPLETE.FAIL: {
      return {
        ...state,
        loadBasedAutocomplete: [],
        loadBasedAutocompleteStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
