import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_COMPANY_MEMBERS = define('GET_COMPANY_MEMBERS');

export function getCompanyMembersRequest(companyId) {
  return GET_COMPANY_MEMBERS.request(() => Api.getCompanyMembers(companyId));
}

export const SEND_INVITATION_TO_MEMBER = define('SEND_INVITATION_TO_MEMBER');

export function sendInvitationToMemberRequest(data) {
  return SEND_INVITATION_TO_MEMBER.request(() => Api.sendInvitationToMember(data));
}

export const REMOVE_INVITATION = define('REMOVE_INVITATION');

export function removeInvitationRequest(data) {
  return REMOVE_INVITATION.request(() => Api.removeInvitation(data));
}

export const UPDATE_MEMBER_STATUS = define('UPDATE_MEMBER_STATUS');

export function updateMemberStatusRequest(data) {
  return UPDATE_MEMBER_STATUS.request(() => Api.updateMemberStatus(data), { data });
}

export const REGISTER_MEMBER = define('REGISTER_MEMBER');

export function registerMemberRequest(data) {
  return REGISTER_MEMBER.request(() => Api.registerMember(data));
}

export const UPDATE_MEMBER_ROLE = define('UPDATE_MEMBER_ROLE');

export function updateMemberRoleRequest(data) {
  return UPDATE_MEMBER_ROLE.request(() => Api.updateMemberRole(data));
}
