const { REACT_APP_MODE } = process.env;
const config = {
  dev: {
    API_URL: 'https://api-dev.zerobroker.com',
    PLAID_MODE: 'sandbox',
    POSTHOG_API_HOST: 'https://app.posthog.com',
    POSTHOG_APY_KEY: 'phc_x9ob8oIEuvrrmTdVshHTWcROVxqxtDrU7Rz0W6NfWll',
  },
  prod: {
    API_URL: 'https://api-v1.zerobroker.com',
    PLAID_MODE: 'production',
    POSTHOG_API_HOST: 'https://posthog.zerobroker.com',
    POSTHOG_APY_KEY: 'phc_JLedIgEWdKAjhFcB53XE8hIed0nb6ED8SHZ3rgpJedr',
  },
  loc: {
    API_URL: 'http://localhost:4000',
    PLAID_MODE: 'sandbox',
    POSTHOG_API_HOST: 'https://app.posthog.com',
    POSTHOG_APY_KEY: 'phc_x9ob8oIEuvrrmTdVshHTWcROVxqxtDrU7Rz0W6NfWll',
  },
  staging: {
    API_URL: 'https://api-staging.zerobroker.com',
    PLAID_MODE: 'sandbox',
    POSTHOG_API_HOST: 'https://app.posthog.com',
    POSTHOG_APY_KEY: 'phc_x9ob8oIEuvrrmTdVshHTWcROVxqxtDrU7Rz0W6NfWll',
  },
  payment: {
    API_URL: 'https://payment-api.zerobroker.com',
    PLAID_MODE: 'sandbox',
    POSTHOG_API_HOST: 'https://app.posthog.com',
    POSTHOG_APY_KEY: 'phc_x9ob8oIEuvrrmTdVshHTWcROVxqxtDrU7Rz0W6NfWll',
  },
};

module.exports = config[REACT_APP_MODE] || config.prod;
