import axios from 'axios';
import queryString, { stringify as qs } from 'query-string';
import { serialize } from 'object-to-formdata';
import Account from './helpers/Account';
import config from './config';

const apiUrl = config.API_URL;

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// eslint-disable-next-line no-shadow
api.interceptors.request.use((config) => {
  const token = Account.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((res) => res, (error) => {
  if (error?.response?.status === 401) {
    Account.delete();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

const apiTokens = {};

function toFormData(data, indices = false) {
  return serialize({ ...data }, { indices });
}

class Api {
  static cancel(methodName) {
    if (apiTokens[methodName]) {
      apiTokens[methodName]();
    }
  }

  static cancelToken(methodName) {
    return new axios.CancelToken((token) => {
      apiTokens[methodName] = token;
    });
  }

  static signUpStepOne(data) {
    return api.post('/users/register/shipper/step-one', data);
  }

  static signUpStepTwo(data) {
    return api.post('/users/register/shipper/step-two', data);
  }

  static signUpLeadsStepOne(data) {
    return api.post('/users/register/lead/shipper/step-one', data);
  }

  static signUpLeadsStepTwo(data) {
    return api.post('/users/register/lead/shipper/step-two', data);
  }

  static signUpStepThree(data) {
    return api.post('/users/register/shipper/step-three', data);
  }

  static forgotPassword(data) {
    return api.post('/users/forgot/password', data);
  }

  static setPassword(data) {
    return api.put('/users/forgot/password', data);
  }

  static getUserProfile() {
    return api.get('/users/profile');
  }

  static getUserProfileById(id) {
    return api.get(`/users/profile?userId=${id}&get=children`);
  }

  static login(data) {
    return api.post('/users/login', data);
  }

  static googleAutocomplete(s) {
    this.cancel('googleAutocomplete');
    const query = qs(s);
    return api.get(`/places/autocomplete?${query}`, { cancelToken: this.cancelToken('googleAutocomplete') });
  }

  static getPlaceById(id) {
    const query = qs({ id });
    return api.get(`/places/get?${query}`);
  }

  static getRouteData(data) {
    return api.get('/places/get/route', { params: { ...data } });
  }

  static shipmentEstimation(data) {
    const query = qs(data);
    return api.get(`/places/estimate?${query}`, { cancelToken: this.cancelToken('shipmentEstimation') });
  }

  static resendEmail(data) {
    return api.post('/users/register/shipper/resend', data);
  }

  static createShipment(data, token) {
    return api.post('/loads/create', data, token ? { headers: { Authorization: `Bearer ${token}` } } : null);
  }

  static getShipments(status, limit, userType, id, selectedUserType, s, page = 1) {
    this.cancel('getShipments');
    let userId = {};
    if (selectedUserType) {
      userId = { [`${selectedUserType}Id`]: id };
    }
    return api.get('/loads/list', {
      cancelToken: this.cancelToken('getShipments'),
      params: {
        status, limit, ...userId, s, page,
      },
    });
  }

  static cancelShipment(id) {
    return api.get(`/loads/cancel/${id}`);
  }

  static getShipmentById(id) {
    return api.get(`/loads/single/${id}`);
  }

  static getUserCompanies(s, page = 1) {
    if (page === 1) {
      this.cancel('getUserCompanies');
    }
    return api.get('/users/companies', { params: { s, page }, cancelToken: this.cancelToken('getUserCompanies') });
  }

  static uploadShipmentDocument(data) {
    return api.post('/loads/upload/document', toFormData(data, true));
  }

  static deleteShipmentDocument(id) {
    return api.delete('/loads/delete/document', { data: { id } });
  }

  static loadsAssign(data) {
    return api.post('/loads/assign', data);
  }

  static searchMc(s, trailerType) {
    return api.get('/users/carriers', { params: { s, trailerType } });
  }

  static updateShipment(data) {
    return api.put('/loads/update', data);
  }

  static updateStatus(data) {
    return api.put('/loads/update/status', data);
  }

  static uploadProfile(data) {
    return api.put('/users/profile/update', toFormData(data));
  }

  static updatePaymentStatus(data) {
    return api.put('/loads/update/payment/status', data);
  }

  static createShipper(data) {
    return api.post('/users/create/shipper/step-one', data);
  }

  static createShipperTwo(data) {
    return api.post('/users/create/shipper/step-two', data);
  }

  static getShippersList(s) {
    return api.get('/users/shippers', { params: { s } });
  }

  static addManager(data) {
    return api.post('/users/create/manager/step-one', data);
  }

  static setManagerPassword(data) {
    return api.post('/users/create/user/step-two', data);
  }

  static assignPhone(data) {
    return api.post('loads/assign/phone', data);
  }

  static userProfileConfirm(id) {
    return api.get('/users/profile/confirm', { params: { id } });
  }

  static userLoginAfterConfirmation() {
    return api.get('/users/logged/in');
  }

  static deleteShipment(id) {
    return api.delete('/loads/delete', { data: { id } });
  }

  static uploadXLSXFile(data) {
    return api.post('/data-templates/import/xlsx', toFormData(data));
  }

  static uploadCSVFile(data) {
    return api.post('/carriers-list/import/csv', toFormData(data));
  }

  static getCarriersList(page) {
    return api.get('/carriers-list', { params: { page } });
  }

  static getNewSms(id) {
    return api.get('/carriers-list/notify', { params: { id }, timeout: 120000 });
  }

  static getNewSmsNo(id) {
    return api.get('/carriers-list/can-notify-more', { params: { id } });
  }

  static deleteUser(id) {
    return api.delete(`/users/delete/${id}`);
  }

  static getCarriersAllStatuses(s) {
    this.cancel('getCarriersALlStatuses');
    return api.get('/carriers-list/all', { params: { s }, cancelToken: this.cancelToken('getCarriersALlStatuses') });
  }

  static getCarriersByStatus(status, page = 1, s, filter) {
    this.cancel('getCarriersByStatus');
    return api.get('/carriers-list/by-status', {
      params: {
        status, page, s, filter,
      },
      cancelToken: this.cancelToken('getCarriersByStatus'),
    });
  }

  static createCarrier(data) {
    return api.post('/users/create/carrier', data);
  }

  static getCarrierById(id) {
    return api.get('/carriers-list/carrier', { params: { id } });
  }

  static postShipment(id) {
    return api.get(`/loads/post/${id}`);
  }

  static unpostShipment(id) {
    return api.delete(`/loads/post/${id}`);
  }

  static getPlaidLinkToken() {
    return api.post('/payment/link-token');
  }

  static exchangePlaidToken(data) {
    return api.post('/payment/exchange-token', data);
  }

  static checkPlaidAccount(userId = undefined) {
    return api.post('/payment/plaid/verified-account', { userId });
  }

  static uploadFiles(data) {
    return api.post('/users/profile/upload', toFormData(data));
  }

  static chargeFromShipper(data) {
    return api.post('/payment/create-charge', data);
  }

  static payToDriver(shipmentId, accountId, userId, status, updatedFields) {
    return api.post('/payment/driver-pay', {
      shipmentId, accountId, userId, status, updatedFields,
    });
  }

  static getCompanyMembers(companyId) {
    return api.get(`/company-management/members/${companyId}`);
  }

  static sendInvitationToMember(data) {
    return api.post('/company-management/members/invitations/send', data);
  }

  static removeInvitation(id) {
    return api.delete(`/company-management/members/invitations/${id}`);
  }

  static updateMemberStatus(data) {
    return api.put('/company-management/members/update/status', data);
  }

  static registerMember(data) {
    return api.post('/company-management/members/register', data);
  }

  static updateShipmentHistory(data) {
    return api.put('/loads/update/history', data);
  }

  static checkIfDriverExists(phone) {
    return api.get('/carriers-list/check', { params: { phone } });
  }

  static updateDrivers(data) {
    return api.put('/users/update/drivers', { data });
  }

  static cancelDriver(id) {
    return api.get(`/loads/book/cancel/${id}`);
  }

  static changeCarrier(data) {
    return api.post('/loads/change/carrier', data);
  }

  static getShipmentUpdateHistory(id, page = 1) {
    return api.get(`/loads/history/${id}`, { params: { page } });
  }

  static getBankAccountInfo() {
    return api.get('/payment/plaid/account');
  }

  static deleteBankAccount(id) {
    return api.delete('/payment/plaid/delete', { data: { id } });
  }

  static makeAccountStatus(id, status) {
    return api.put('/payment/plaid/account/status', { id, status });
  }

  static getUserAccountsData(id) {
    return api.get(`/payment/plaid/account?userId=${id}`);
  }

  static getInvoicesData(userId, page) {
    return api.get('/loads/invoices', { params: { userId, page } });
  }

  static hideInvoice(id, checked) {
    return api.get(`/loads/invoices/${id}/toggle/visibility?hide=${checked}`);
  }

  static getInvoicesBalanceData(userId) {
    return api.get('/loads/invoices/balance', { params: { userId } });
  }

  static getScopes() {
    return api.get('/roles/scopes');
  }

  static getScopeGroups() {
    return api.get('/roles/scopes/groups');
  }

  static getRoles(id, page) {
    const query = queryString.stringify({ id, page }, { skipEmptyString: true });
    return api.get(`/roles?${query}`);
  }

  static createRole(name, scopes) {
    return api.post('/roles/create', { name, scopes });
  }

  static updateRole(id, name, scopes) {
    return api.put('/roles/update', { id, name, scopes });
  }

  static createUser(data) {
    return api.post('/users/create/user/step-one', data);
  }

  static getUsers(page, params) {
    return api.get('/users', { params: { page, ...params } });
  }

  static deleteRole(id) {
    return api.delete(`/roles/delete/${id}`);
  }

  static updateUserStatus(data) {
    return api.post('/users/update', data);
  }

  static getRatesData(data) {
    this.cancel('getRatesData');

    return api.get('/chart', {
      params: { ...data },
      timeout: 120000,
      cancelToken: this.cancelToken('getRatesData'),
    });
  }

  static getContractChartData(data) {
    this.cancel('getContractChartData');

    return api.get('/chart/dat', {
      params: { ...data },
      timeout: 120000,
      cancelToken: this.cancelToken('getContractChartData'),
    });
  }

  static createSubscriptionPlan(data) {
    return api.post('/subscriptions/create', data);
  }

  static updateSubscriptionPlan(data) {
    return api.put('/subscriptions/update', data);
  }

  static updateSubscriptionPlanStatus(data) {
    return api.put('/subscriptions/update/status', data);
  }

  static getSubscriptionPlans(companyId) {
    return api.get('/subscriptions/invoices', { params: { companyId } });
  }

  static getCurrentSubscriptionPlan(companyId) {
    return api.get('/subscriptions/current', { params: { companyId } });
  }

  static getCompanyRoles() {
    return api.get('/roles/company');
  }

  static updateMemberRole(data) {
    return api.put('/company-management/members/update/company-role', data);
  }

  static markInvoicePaid(id, companyId) {
    return api.post('/subscriptions/invoices/mark/paid', { id, companyId });
  }

  static createDiscount(data) {
    return api.post('/discounts/create', data);
  }

  static createDiscountState(data) {
    return api.post('/discounts/state/create', data);
  }

  static updateDiscount(data) {
    return api.put('/discounts/update', data);
  }

  static updateDiscountState(data) {
    return api.put('/discounts/state/update', data);
  }

  static getDiscounts(page = 1) {
    return api.get('/discounts', { params: { page } });
  }

  static getDiscountsState(page = 1) {
    return api.get('/discounts/state', { params: { page } });
  }

  static deleteDiscount(id) {
    return api.delete('/discounts/delete', { data: { id } });
  }

  static deleteDiscountState(id) {
    return api.delete('/discounts/state/delete', { data: { id } });
  }

  static loadBasedAutocomplete(shipperId, targetField, s) {
    return api.get('/places/autocomplete/load-based', { params: { shipperId, targetField, s } });
  }

  static loadAutofill(shipperId, origin, destination, equipment) {
    return api.get('/places/autofill/load-based', {
      params: {
        shipperId, origin, destination, equipment,
      },
    });
  }

  static updateGlobalDiscount(percentage) {
    return api.put('/discounts/update/global', { percentage });
  }

  static pauseCompanyAccount(companyId, paused = 0) {
    return api.get('/users/profile/pause', { params: { companyId, paused } });
  }

  static generateApiKey() {
    return api.post('/v1/integration/generate-keys');
  }

  static updateApiKey() {
    return api.put('/v1/integration/generate-keys');
  }

  static getApiKey() {
    return api.get('/v1/integration/keys');
  }

  static sendQuotes(email, callbackUrl) {
    return api.post('/users/quotes/collect', { email, callbackUrl });
  }

  static createLead(data) {
    return api.post('/lead-management/leads', data);
  }

  static updateLead(data) {
    return api.put('/lead-management/leads', data);
  }

  static getLeads(params) {
    return api.get('/lead-management/leads', { params });
  }

  static getLead(id) {
    return api.get('/lead-management/leads', { params: { id } });
  }

  static addLeadMember(data) {
    return api.post('/lead-management/leads/members', data);
  }

  static updateLeadMember(data) {
    return api.put('/lead-management/leads/members', data);
  }

  static getLeadMembers(params) {
    return api.get('/lead-management/leads/members', { params });
  }

  static getLeadMember(id, leadId) {
    return api.get('/lead-management/leads/members', { params: { id, leadId } });
  }

  static getLeadLanes(params) {
    return api.get('/lead-management/leads/lanes', { params });
  }

  static getLeadLane(id, leadId) {
    return api.get('/lead-management/leads/lanes', { params: { id, leadId } });
  }

  static createLeadLane(data) {
    return api.post('/lead-management/leads/lanes', data);
  }

  static updateLeadLane(data) {
    return api.put('/lead-management/leads/lanes', data);
  }

  static deleteLeadLane(id) {
    return api.delete(`/lead-management/leads/lanes/${id}`);
  }

  static updateLeadMemberStatus(id, status) {
    return api.put('/lead-management/leads/members/status', { id, status });
  }

  static updateLeadSubscriptionStatus(data) {
    return api.put('/lead-management/leads/lanes/subscription/status', data);
  }

  static getLeadInfo(token) {
    return api.post('/users/register/lead/shipper/step-one/data', { token });
  }

  static setWebhookUrl(url) {
    return api.post('/v1/integration/webhook/set', { url });
  }

  static deleteWebhookUrl() {
    return api.delete('/v1/integration/webhook/delete');
  }

  static getStates() {
    return api.get('/settings/states/us');
  }
}

export default Api;
