import posthog from 'posthog-js';

document.body.addEventListener('change', (ev) => {
  try {
    const name = ev.target?.getAttribute('name') || '';
    if (!name.startsWith('posthog[')) {
      return;
    }
    const [, property] = name.match(/posthog\[(.+)]/);
    posthog.capture('type on', { fieldType: 'input', label: property, value: ev.target.value });
  } catch (e) {
    console.error(e);
  }
});

(() => {
  try {
    const observer = new MutationObserver((mutations) => {
      mutations.some((mutation) => {
        if (mutation.type !== 'attributes') {
          return false;
        }
        if (mutation.target.getAttribute('type') !== 'hidden') {
          return false;
        }

        const name = mutation.target.getAttribute('name') || '';
        if (!name.startsWith('posthog[')) {
          return false;
        }

        const [, property] = name.match(/posthog\[(.+)]/);
        posthog.capture('type on', { fieldType: 'select', label: property, value: mutation.target.value });
        return true;
      });
    });

    observer.observe(document.body, {
      attributes: true,
      subtree: true,
    });
  } catch (e) {
    console.error(e);
  }
})();

window.addEventListener('beforeunload', () => {
  document.activeElement.blur();
});
