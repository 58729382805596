import {
  GET_LEADS, GET_LEAD_MEMBERS, GET_LEAD_LANES, GET_LEAD,
} from '../actions/leads';

const initialState = {
  leads: [],
  leadsStatus: '',
  leadMembers: [],
  leadMembersStatus: '',
  leadLanes: [],
  leadLanesStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEADS.REQUEST: {
      return {
        ...state,
        leadsStatus: 'request',
      };
    }
    case GET_LEADS.SUCCESS: {
      const { leads } = action.payload.data;
      return {
        ...state,
        leadsStatus: 'ok',
        leads,
      };
    }
    case GET_LEADS.FAIL: {
      return {
        ...state,
        leadsStatus: 'fail',
      };
    }

    case GET_LEAD_MEMBERS.REQUEST: {
      return {
        ...state,
        leadMembersStatus: 'request',
        leadMembers: [],
      };
    }
    case GET_LEAD_MEMBERS.SUCCESS: {
      const { leadMembers } = action.payload.data;
      return {
        ...state,
        leadMembersStatus: 'ok',
        leadMembers,
      };
    }
    case GET_LEAD_MEMBERS.FAIL: {
      return {
        ...state,
        leadMembersStatus: 'fail',
      };
    }
    case GET_LEAD.SUCCESS: {
      const { leads } = action.payload.data;
      return {
        ...state,
        leadMembers: [leads[0], ...state.leadMembers],
      };
    }
    case GET_LEAD_LANES.REQUEST: {
      return {
        ...state,
        leadLanesStatus: 'request',
        leadLanes: [],
      };
    }
    case GET_LEAD_LANES.SUCCESS: {
      const { leadLanes } = action.payload.data;
      return {
        ...state,
        leadLanesStatus: 'ok',
        leadLanes,
      };
    }
    case GET_LEAD_LANES.FAIL: {
      return {
        ...state,
        leadLanesStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
