import { combineReducers } from 'redux';
import signUp from './signUp';
import account from './account';
import shipments from './shipments';
import manager from './manager';
import carriers from './carriers';
import plaid from './plaid';
import companyManagement from './companyManagement';
import roles from './roles';
import rates from './rates';
import subscription from './subscription';
import discounts from './discounts';
import apiKey from './apiKey';
import leads from './leads';

export default combineReducers({
  account,
  signUp,
  shipments,
  manager,
  carriers,
  plaid,
  companyManagement,
  roles,
  rates,
  subscription,
  discounts,
  apiKey,
  leads,
});
