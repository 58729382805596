import _ from 'lodash';
import {
  GET_USER_PROFILE,
  USER_LOGIN, SET_TOKEN,
  GET_USER_PROFILE_BY_ID,
  USER_PROFILE_UPDATE,
  DELETE_ERROR_BY_KEY,
  CREATE_SHIPPER_REQUEST, CHANGE_ACCOUNT_DATA, SET_ACCOUNT_FORM_DATA,
} from '../actions/account';
import { SIGN_UP_STEP_THREE } from '../actions/signUp';
import { CREATE_CARRIER } from '../actions/carriers';
import Account from '../../helpers/Account';

const initialState = {
  userProfileData: Account.get(),
  token: Account.getToken(),
  userProfileStatus: '',
  userProfile: {},
  formErrors: {},
  formData: {
    fName: '',
    lName: '',
    companyName: '',
    email: '',
    additionalEmail: '',
    trailerType: '',
    note: '',
    mc: '',
    usDot: '',
    truckId: '',
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_PROFILE.REQUEST: {
      return {
        ...state,
        formErrors: {},
      };
    }
    case GET_USER_PROFILE.SUCCESS: {
      const { user } = action.payload.data;
      Account.set(user);
      return {
        ...state,
        userProfileData: user,
      };
    }
    case GET_USER_PROFILE.FAIL: {
      const status = action?.payload?.data?.status;
      if (status === 401 || status === 403) {
        Account.delete();
        window.location.reload();
      }
      return {
        ...state,
      };
    }
    case SIGN_UP_STEP_THREE.SUCCESS: {
      const { token } = action.payload.data;
      Account.setToken(token);
      return {
        ...state,
        token,
      };
    }
    case USER_LOGIN.SUCCESS: {
      const { token } = action.payload.data;
      Account.setToken(token);
      return {
        ...state,
        token,
      };
    }
    case SET_TOKEN: {
      const { token } = action.payload;
      Account.setToken(token);
      return {
        ...state,
        token,
      };
    }
    case GET_USER_PROFILE_BY_ID.REQUEST: {
      return {
        ...state,
        formErrors: {},
        userProfileStatus: 'request',
      };
    }
    case GET_USER_PROFILE_BY_ID.SUCCESS: {
      return {
        ...state,
        userProfile: action.payload.data,
        userProfileStatus: 'ok',

      };
    }
    case GET_USER_PROFILE_BY_ID.FAIL: {
      return {
        ...state,
        userProfile: {},
        userProfileStatus: 'fail',

      };
    }
    case CREATE_CARRIER.SUCCESS:
    case CREATE_CARRIER.REQUEST: {
      return {
        ...state,
        formErrors: {},
      };
    }
    case USER_PROFILE_UPDATE.REQUEST: {
      return {
        ...state,
        formErrors: {},
      };
    }
    case CREATE_SHIPPER_REQUEST.FAIL:
    case CREATE_CARRIER.FAIL:
    case USER_PROFILE_UPDATE.FAIL: {
      return {
        ...state,
        formErrors: action.payload?.data?.errors,
      };
    }
    case DELETE_ERROR_BY_KEY: {
      const { key } = action.payload;
      const { formErrors } = state;
      delete formErrors[key];
      return {
        ...state,
        formErrors: { ...formErrors },
      };
    }
    case CHANGE_ACCOUNT_DATA: {
      const { key, value } = action.payload;
      const { formData } = state;
      _.set(formData, key, value);
      return {
        ...state,
        formData: { ...formData },
      };
    }
    case SET_ACCOUNT_FORM_DATA: {
      const { formData } = action.payload;
      return {
        ...state,
        formData: { ...formData },
      };
    }
    default: {
      return state;
    }
  }
}
