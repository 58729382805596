import { GET_COMPANY_MEMBERS, UPDATE_MEMBER_STATUS } from '../actions/companyManagement';

const initialState = {
  companyMembers: {},
  companyMembersStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MEMBER_STATUS.REQUEST: {
      const companyMembers = { ...state.companyMembers };
      const { status, userId } = action.payload.data;
      companyMembers.members = companyMembers?.members?.map((m) => {
        if (userId === m._id) m.userStatus = status;
        return m;
      });
      return {
        ...state,
        companyMembers,
      };
    }
    case GET_COMPANY_MEMBERS.REQUEST: {
      return {
        ...state,
        companyMembersStatus: 'request',
      };
    }
    case GET_COMPANY_MEMBERS.SUCCESS: {
      return {
        ...state,
        companyMembersStatus: 'ok',
        companyMembers: action.payload.data,
      };
    }
    case GET_COMPANY_MEMBERS.FAIL: {
      return {
        ...state,
        companyMembersStatus: 'fail',
        companyMembers: {},
      };
    }
    default: {
      return state;
    }
  }
}
